<template>
  <section>
    <section class="columns is-multiline is-centered has-background-light">
      <section
        id="privacy-policy"
        class="column is-10-desktop is-11-tablet is-12-mobile"
      >
        <section class="title-container has-text-centered">
          <h1 class="title is-3">Polityka prywatności</h1>
          <h2 class="subtitle is-6">Zaktualizowano: 19.04.2021</h2>
        </section>
        <section class="box">
          <h1 class="title is-5 is-privacy-row">
            POSTANOWIENIA OGÓLNE
          </h1>
          <p class="subtitle is-6 is-privacy-row">
            Administratorem danych osobowych zbieranych za pośrednictwem strony
            internetowej www.techniker.pl jest Techniker ZT Sp. Z.o.o, adres
            siedziby: Główna 61, 61-019 Poznań, adres do doręczeń: , wpisaną do
            rejestru przedsiębiorców pod numerem KRS: 0000817075, NIP:
            7822874214, REGON: 384995666, posiadającą kapitał zakładowy w
            kwocie: 5000 , adres poczty elektronicznej: biuro@techniker.pl dalej
            „Administrator”, będąca jednocześnie Usługodawcą. , miejsce
            wykonywania działalności: Główna 61, 61-019 Poznań, adres do
            doręczeń: Główna 61, 61-019 Poznań, NIP: 7822874214, REGON:
            384995666, adres poczty elektronicznej (e-mail): biuro@techniker.pl,
            zwany/a dalej "Administratorem".
          </p>
          <p class="subtitle is-6 is-privacy-row">
            Dane osobowe zbierane przez Administratora za pośrednictwem strony
            internetowej są przetwarzane zgodnie z Rozporządzeniem Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z
            dnia 10 maja 2018 r.
          </p>

          <h1 class="title is-5 is-privacy-row">
            RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA
            DANYCH
          </h1>

          <ol class="has-indent">
            <li>
              <p class="title is-6 is-privacy-row">
                CEL PRZETWARZANIA I PODSTAWA PRAWNA.
              </p>
              <p class="subtitle is-6 is-privacy-row">
                Administrator przetwarza dane osobowe za pośrednictwem strony
                www.techniker.pl w przypadku skorzystania przez użytkownika z
                formularza kontaktowego. Dane osobowe są przetwarzane na
                podstawie art. 6 ust. 1 lit. f) RODO jako prawnie
                usprawiedliwiony interes Administratora, oraz w przypadku
                zapisania się przez użytkownika do Newslettera w celu
                przesyłania informacji handlowych drogą elektroniczną. Dane
                osobowe są przetwarzane po wyrażeniu odrębnej zgody, na
                podstawie art. 6 ust. 1 lit. a) RODO.
              </p>
            </li>

            <li>
              <p class="title is-6 is-privacy-row">
                RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH.
              </p>
              <p class="subtitle is-6 is-privacy-row">
                Administrator przetwarza następujące kategorie danych osobowych
                użytkownika: Imię i nazwisko, Adres e-mail, Numer telefonu,
              </p>
            </li>

            <li>
              <p class="title is-6 is-privacy-row">
                OKRES ARCHIWIZACJI DANYCH OSOBOWYCH.
              </p>
              <p class="subtitle is-6 is-privacy-row">
                Dane osobowe użytkowników przechowywane są przez Administratora:
              </p>
              <ol class="has-indent">
                <li>
                  w przypadku, gdy podstawą przetwarzania danych jest wykonanie
                  umowy, tak długo, jak jest to niezbędne do wykonania umowy, a
                  po tym czasie przez okres odpowiadający okresowi przedawnienia
                  roszczeń. Jeżeli przepis szczególny nie stanowi inaczej,
                  termin przedawnienia wynosi lat sześć, a dla roszczeń o
                  świadczenia okresowe oraz roszczeń związanych z prowadzeniem
                  działalności gospodarczej - trzy lata.
                </li>
                <li>
                  w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak
                  długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody
                  przez okres czasu odpowiadający okresowi przedawnienia
                  roszczeń jakie może podnosić Administrator i jakie mogą być
                  podnoszone wobec niego. Jeżeli przepis szczególny nie stanowi
                  inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń
                  o świadczenia okresowe oraz roszczeń związanych z prowadzeniem
                  działalności gospodarczej - trzy lata.
                </li>
              </ol>
            </li>

            <li class="has-margin-top">
              Podczas korzystania ze strony internetowej mogą być pobierane
              dodatkowe informacje, w szczególności: adres IP przypisany do
              komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu,
              nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu
              operacyjnego.
            </li>

            <li class="has-margin-top">
              Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym
              informacje o linkach i odnośnikach, w które zdecydują się kliknąć
              lub innych czynnościach, podejmowanych na stronie internetowej.
              Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony
              interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na
              ułatwieniu korzystania z usług świadczonych drogą elektroniczną
              oraz na poprawie funkcjonalności tych usług.
            </li>

            <li class="has-margin-top">
              Podanie danych osobowych przez użytkownika jest dobrowolne.
            </li>

            <li class="has-margin-top">
              Administrator dokłada szczególnej staranności w celu ochrony
              interesów osób, których dane dotyczą, a w szczególności zapewnia,
              że zbierane przez niego dane są: przetwarzane zgodnie z prawem,
              zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane
              dalszemu przetwarzaniu niezgodnemu z tymi celami, merytorycznie
              poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane
              oraz przechowywane w postaci umożliwiającej identyfikację osób,
              których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia
              celu przetwarzania.
            </li>
          </ol>

          <h1 class="title is-5 is-privacy-row has-margin-top">
            UDOSTĘPNIENIE DANYCH OSOBOWYCH
          </h1>
          <ol class="has-indent">
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Dane osobowe użytkowników przekazywane są dostawcom usług, z
                których korzysta Administrator przy prowadzeniu strony
                internetowej. Dostawcy usług, którym przekazywane są dane
                osobowe, w zależności od uzgodnień umownych i okoliczności, albo
                podlegają poleceniom Administratora co do celów i sposobów
                przetwarzania tych danych (podmioty przetwarzające) albo
                samodzielnie określają cele i sposoby ich przetwarzania
                (administratorzy).
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Dane osobowe użytkowników są przechowywane wyłącznie na terenie
                Europejskiego Obszaru Gospodarczego (EOG).
              </p>
            </li>
          </ol>

          <h1 class="title is-5 is-privacy-row has-margin-top">
            PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH
            POPRAWIANIA
          </h1>
          <ol class="has-indent">
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Osoba, której dane dotyczą, ma prawo dostępu do treści swoich
                danych osobowych oraz prawo ich sprostowania, usunięcia,
                ograniczenia przetwarzania, prawo do przenoszenia danych, prawo
                wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym
                momencie bez wpływu na zgodność z prawem przetwarzania, którego
                dokonano na podstawie zgody przed jej cofnięciem.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Podstawy prawne żądania użytkownika: Dostęp do danych – art. 15
                RODO Sprostowanie danych – art. 16 RODO. Usunięcie danych (tzw.
                prawo do bycia zapomnianym) – art. 17 RODO. Ograniczenie
                przetwarzania – art. 18 RODO. Przeniesienie danych – art. 20
                RODO. Sprzeciw – art. 21 RODO Cofnięcie zgody – art. 7 ust. 3
                RODO.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać
                stosowną wiadomość e-mail na adres: biuro@techniker.pl.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                W sytuacji wystąpienia przez użytkownika z uprawnieniem
                wynikającym z powyższych praw, Administrator spełnia żądanie
                albo odmawia jego spełnienia niezwłocznie, nie później jednak
                niż w ciągu miesiąca po jego otrzymaniu. Jeżeli jednak - z uwagi
                na skomplikowany charakter żądania lub liczbę żądań –
                Administrator nie będzie mógł spełnić żądania w ciągu miesiąca,
                spełni je w ciągu kolejnych dwóch miesięcy informując
                użytkownika uprzednio w terminie miesiąca od otrzymania żądania
                - o zamierzonym przedłużeniu terminu oraz jego przyczynach.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                W przypadku stwierdzenia, że przetwarzanie danych osobowych
                narusza przepisy RODO, osoba, której dane dotyczą, ma prawo
                wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.
              </p>
            </li>
          </ol>

          <h1 class="title is-5 is-privacy-row has-margin-top">
            PLIKI "COOKIES"
          </h1>
          <ol class="has-indent">
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Strona Administratora używa plików „cookies”.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Instalacja plików „cookies” jest konieczna do prawidłowego
                świadczenia usług na stronie internetowej. W plikach „cookies"
                znajdują się informacje niezbędne do prawidłowego funkcjonowania
                strony, a także dają one także możliwość opracowywania ogólnych
                statystyk odwiedzin strony internetowej.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                W ramach strony stosowane są rodzaje plików "cookies": sesyjne i
                stałe „Cookies” „sesyjne” są plikami tymczasowymi, które
                przechowywane są w urządzeniu końcowym użytkownika do czasu
                wylogowania (opuszczenia strony). „Stałe” pliki „cookies”
                przechowywane są w urządzeniu końcowym użytkownika przez czas
                określony w parametrach plików „cookies” lub do czasu ich
                usunięcia przez użytkownika.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Administrator wykorzystuje własne pliki cookies w celu lepszego
                poznania sposobu interakcji użytkownika w zakresie zawartości
                strony. Pliki gromadzą informacje o sposobie korzystania ze
                strony internetowej przez użytkownika, typie strony, z jakiej
                użytkownik został przekierowany oraz liczbie odwiedzin i czasie
                wizyty użytkownika na stronie internetowej. Informacje te nie
                rejestrują konkretnych danych osobowych użytkownika, lecz służą
                do opracowania statystyk korzystania ze strony.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Użytkownik ma prawo zadecydowania w zakresie dostępu plików
                „cookies” do swojego komputera poprzez ich uprzedni wybór w
                oknie swojej przeglądarki. Szczegółowe informacje o możliwości i
                sposobach obsługi plików „cookies” dostępne są w ustawieniach
                oprogramowania (przeglądarki internetowej).
              </p>
            </li>
          </ol>

          <h1 class="title is-5 is-privacy-row has-margin-top">
            POSTANOWIENIA KOŃCOWE
          </h1>
          <ol class="has-indent">
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Administrator stosuje środki techniczne i organizacyjne
                zapewniające ochronę przetwarzanych danych osobowych odpowiednią
                do zagrożeń oraz kategorii danych objętych ochroną, a w
                szczególności zabezpiecza dane przed ich udostępnieniem osobom
                nieupoważnionym, zabraniem przez osobę nieuprawnioną,
                przetwarzaniem z naruszeniem obowiązujących przepisów oraz
                zmianą, utratą, uszkodzeniem lub zniszczeniem.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                Administrator udostępnia odpowiednie środki techniczne
                zapobiegające pozyskiwaniu i modyfikowaniu przez osoby
                nieuprawnione, danych osobowych przesyłanych drogą
                elektroniczną.
              </p>
            </li>
            <li>
              <p class="subtitle is-6 is-privacy-row">
                W sprawach nieuregulowanych niniejszą Polityką prywatności
                stosuje się odpowiednio przepisy RODO oraz inne właściwe
                przepisy prawa polskiego.
              </p>
            </li>
          </ol>
        </section>
      </section>
    </section>
    <contact />
    <foot />
  </section>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';
import LoadingComponent from '@/components/LoadingComponent';
import ErrorComponent from '@/components/ErrorComponent';

export default {
  name: 'PrivacyPolicy',
  components: {
    foot: () => ({
      component: import('../components/Footer'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
    contact: () => ({
      component: import('../components/ContactForm'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
  },
  mounted() {
    smoothscroll.polyfill();
    const element = document.getElementById('privacy-policy');
    element.scrollIntoView({ behavior: 'smooth' });
  },
};
</script>

<style lang="scss" scoped>
$grey-light: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

.has-background-light {
  background-color: $grey-light;
}

.box {
  background-color: $white;
  width: 100%;
  height: 100%;

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.011),
    0 6.7px 5.3px rgba(0, 0, 0, 0.016), 0 12.5px 10px rgba(0, 0, 0, 0.02),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 41.8px 33.4px rgba(0, 0, 0, 0.028),
    0 100px 80px rgba(0, 0, 0, 0.04);

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }

  @media only screen and (min-width: 769px) {
    padding: 2.5rem;
  }
}

.title-container {
  margin-top: 15vh;
  margin-bottom: 2rem;
}

.is-privacy-row {
  text-align: justify;
  line-height: 1.5;
}

.subtitle {
  padding-top: 1rem;
  margin-left: 1rem;
}

.has-indent {
  margin-left: 2rem;
}

.has-margin-top {
  margin-top: 1rem;
}

section {
  height: calc(100% + 15rem);
}
</style>
